<template>
  <App
    title="编辑购买信息"
    left-arrow
  >
    <CarOwnerModel
      ref="carOwnerModel"
      :info="orderInfo"
    />
    <div
      slot="footer"
      class="footer"
    >
      <Button @click="$page.close()">取消</Button>
      <Button @click="onSave">确认</Button>
    </div>
  </App>
</template>
<script>
import CarOwnerModel from '_c/business/carOwnerModel'
import { editorder } from '@/api/order'
import { Toast } from 'vant'
export default {
  name: 'buyInfoEdit',
  components: {
    CarOwnerModel
  },
  data () {
    return {
      orderInfo: {}
    }
  },
  methods: {
    onSave () {
      this.$refs.carOwnerModel.validate().then((data) => {
        if (!data) return
        /* Toast.loading({
          message: 'loading...',
          forbidClick: true,
          loadingType: 'spinner'
        }) */
        const params = {
          ...data,
          orderNum: this.orderInfo.orderNum,
          channel: this.orderInfo.channel,
          uid: this.orderInfo.uid,
          superId: this.orderInfo.superId
        }
        editorder(params).then(res => {
          if (res.success) {
            Toast('修改成功')
            this.$router.go(-1)
          } else {
            Toast('提交失败请稍后再试')
          }
        }).finally(() => {
          Toast.clear()
        })
      })
    }
  },
  mounted () {
    this.orderInfo = this.$route.query
  }
}
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  height: 182px;
  line-height: 98px;
  box-sizing: border-box;
  font-size: 32px;
  background-color: #2b2b31;

  button {
    padding: 0 24px;
    width: 148px;
    height: 56px;
    line-height: 1;
    border-radius: 8px;
    font-size: 28px;
  }

  button:nth-of-type(1) {
    color: #DA3738;
    border: 2px solid #DA3738;
    background-color: transparent;
  }

  button:nth-of-type(2) {
    margin-left: 16px;
    color: #fff;
    background-color: #DA3738;
    border: 0;
  }
}
</style>